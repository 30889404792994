import { Page, Text, Document, StyleSheet, Font } from "@react-pdf/renderer";
import PdfHomePage from "./components/Pdfs/PdfHomePage";
import PdfContent from "./components/Pdfs/PdfContent";
import PdfReportDia from "./components/Pdfs/PdfReportDia";
import { AddHealthInfoType, ReportType, UserInfoType } from "../Typing";
import AdditionalPatientInformation from "./components/Pdfs/AdditionalPatientInformation";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create Document Component

const params = new URLSearchParams(window.location.href.split("?")[1]);
type Props = {
  userInfo: UserInfoType | undefined;
  prescriptionReports: ReportType[];
  diagnosticReports: ReportType[];
  getAddHealthInfo: AddHealthInfoType[];
};

const MyDocument = ({
  userInfo,
  prescriptionReports,
  diagnosticReports,
  getAddHealthInfo,
}: Props) => {
  const styles = StyleSheet.create({
    body: {
      height: "100%",
    },

    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  const rowsAtLastPage = prescriptionReports?.length % 9;
  const totalNumberOfPage =
    rowsAtLastPage === 0
      ? (prescriptionReports?.length - rowsAtLastPage) / 9
      : (prescriptionReports?.length - rowsAtLastPage) / 9 + 1;
  //@ts-ignore
  const prescriptionPages = [...Array(totalNumberOfPage).keys()];

  const rowsAtLastPageDia = diagnosticReports?.length % 9;
  const totalNumberOfPageDia =
    rowsAtLastPageDia === 0
      ? (diagnosticReports?.length - rowsAtLastPageDia) / 9
      : (diagnosticReports?.length - rowsAtLastPageDia) / 9 + 1;
  //@ts-ignore
  const diagnosticPages = [...Array(totalNumberOfPageDia).keys()];
  return (
    <Document>
      <Page style={styles.body} size="A4">
        {/* HomePage */}
        <PdfHomePage userInfo={userInfo} />

        {/* Additional Patient Information */}
        {getAddHealthInfo.length > 0 && (
          <AdditionalPatientInformation getAddHealthInfo={getAddHealthInfo} />
        )}

        {/* Table of content */}
        {prescriptionPages?.map((item) => (
          <PdfContent
            id={item}
            Reports={prescriptionReports.slice(item * 9, (item + 1) * 9)}
            reportPageStartAt={0}
          />
        ))}
        {diagnosticPages?.map((item) => (
          <PdfContent
            id={item}
            Reports={diagnosticReports.slice(item * 9, (item + 1) * 9)}
            reportPageStartAt={0}
            prescriptionReports={prescriptionReports}
          />
        ))}

        {/* Reports */}
        {prescriptionReports?.map((item, index) =>
          item.photo_array?.map((photo) => (
            <PdfReportDia {...item} path={photo} id={index} />
          ))
        )}
        {diagnosticReports?.map((item, index) =>
          item.photo_array?.map((photo) => (
            <PdfReportDia {...item} path={photo} id={index} />
          ))
        )}

        {/* Paging */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => {
            const arr = Array.from(
              Array(totalNumberOfPage + totalNumberOfPageDia + 2),
              (_, index) => index + 1
            );

            return `${
              arr.includes(pageNumber) === true
                ? ""
                : `Page ${
                    pageNumber - 2 - totalNumberOfPage - totalNumberOfPageDia
                  } of ${
                    totalPages - 2 - totalNumberOfPage - totalNumberOfPageDia
                  }`
            }`;
          }}
          fixed
        />
      </Page>
    </Document>
  );
};

export default MyDocument;
// ?  `Page ${pageNumber - 1} of ${totalPages - 1}`:""
