import { Page, StyleSheet, View, Text, Link, Image } from "@react-pdf/renderer";
import React from "react";
import { ReportType } from "../../../Typing";
import moment from "moment";

type Props = {
  Reports: ReportType[];
  id: number;

  reportPageStartAt: number;
  prescriptionReports?: ReportType[];
};

const PdfContent = ({
  Reports,
  id,
  reportPageStartAt,
  prescriptionReports,
}: Props) => {
  const styles = StyleSheet.create({
    body: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    mainViewStyle: {
      border: 6,
      borderColor:
        Reports[0].docType.toUpperCase() === "DIAGNOSTIC"
          ? "#718eb2"
          : "#743523",
      //   margin: 15,
      width: "97%",
      height: "97%",
    },
    headingStyle: {
      fontSize: 30,
      padding: 20,
      textAlign: "center",
      color:
        Reports[0].docType.toUpperCase() === "DIAGNOSTIC"
          ? "#718eb2"
          : "#743523",
      fontFamily: "Helvetica",
    },
    page: {
      flexDirection: "column",
      margin: 10,
    },
    imageStyle: {
      width: "60px",
      height: "60px",
      borderRadius: 4,
    },
    table: {
      //@ts-ignore
      display: "table",
      width: "auto",
      borderStyle: "solid",
      paddingHorizontal: 10,
    },
    tableHeader: {
      margin: "auto",
      flexDirection: "row",
      backgroundColor: "#f4f2ff",
      borderWidth: 1,
      borderColor: "#c9c9c9",
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "25%",
      alignItems: "flex-start",
      justifyContent: "center",
      paddingVertical: 5,
    },
    tableCell: {
      //   margin: "auto",
      marginTop: 3,
      marginBottom: 2,

      fontSize: 10,
      textAlign: "left",
      paddingHorizontal: 5,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });
  const currentPageNo = (index: number) => {
    var Sum = 0;

    if (Reports[0].docType.toUpperCase() === "DIAGNOSTIC") {
      if (prescriptionReports) {
        const page = prescriptionReports.map((item) => item.photo_array.length);
        page.forEach((item) => {
          Sum += item;
        });
      }
      const page = Reports.slice(0, index).map(
        (item) => item.photo_array.length
      );

      page.forEach((item) => {
        Sum += item;
      });
    } else {
      const page = Reports.slice(0, index).map(
        (item) => item.photo_array.length
      );

      page.forEach((item) => {
        Sum += item;
      });
    }

    return Sum;
  };
  return (
    <View style={styles.body}>
      <View style={styles.body}>
        <View style={styles.mainViewStyle}>
          {/* title */}
          <Text style={styles.headingStyle}>
            Table of Content of {Reports[0].docType} Report
          </Text>
          {/* Table of content */}
          <View style={styles.table}>
            {/* Table headers */}
            <View style={styles.tableHeader}>
              <View style={[styles.tableCol, { width: "15%" }]}>
                <Text style={styles.tableCell}>Sr. No.</Text>
              </View>
              <View style={[styles.tableCol, { width: "35%" }]}>
                <Text style={styles.tableCell}>File Name</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Page No.</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Date of Upload</Text>
              </View>
            </View>
            {/* Table rows */}
            {Reports.map((item, index) => (
              <View>
                <View style={styles.tableRow}>
                  <View style={[styles.tableCol, { width: "15%" }]}>
                    <Text style={styles.tableCell}>{id * 9 + index + 1}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: "35%" }]}>
                    <View style={{ flexDirection: "row" }}>
                      {/* <Text style={styles.tableCell}>3 User </Text> */}
                      <Image
                        style={styles.imageStyle}
                        src={`https://api.cca.distronix.in:3443/${
                          item.photo_array[item.photo_array.length - 1]
                        }`}
                      />
                      <View style={{ paddingLeft: 5 }}>
                        <Text style={{ fontSize: 10, paddingVertical: 10 }}>
                          {item?.title}
                        </Text>
                        {/* <Text
                          style={{
                            fontSize: 10,
                            paddingVertical: 1,
                            color: "#7e7e7e",
                          }}
                        >
                          200 KB
                        </Text> */}
                      </View>
                    </View>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {reportPageStartAt + currentPageNo(index) + 1}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {moment(item.date).format("lll")}
                    </Text>
                  </View>
                </View>
                {/* diverder */}
                <View
                  style={{
                    backgroundColor: "#d1d1d1",
                    height: 1,
                    width: "100%",
                  }}
                />
              </View>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
};

export default PdfContent;
