import React, { useEffect, useRef, useState } from "react";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import { AddHealthInfoType, ReportType, UserInfoType } from "../../../Typing";
import MyDocument from "../../MyDocument";
import PdfViewer from "../PdfViewer/PdfViewer";

const params = new URLSearchParams(window.location.href.split("?")[1]);
const IndexPdf = () => {
  const [blobData, setBlobData] = useState<Blob | undefined>();
  const [userInfo, setUserInfo] = useState<UserInfoType>();
  const [prescriptionReports, setPrescriptionReports] = useState<ReportType[]>(
    []
  );
  const [diagnosticReports, setDiagnosticReports] = useState<ReportType[]>([]);
  const [getAddHealthInfo, setAddHealthInfo] = useState<AddHealthInfoType[]>(
    []
  );
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    params.get("accessToken") || params.get("citizen") || ""
  );

  var requestOptions: any = {
    method: "get",
    headers: myHeaders,
    redirect: "follow",
  };

  const getDetails = async () => {
    await fetch("https://api.cca.distronix.in:3443/citizen/get", requestOptions)
      .then((response) => response.json())
      .then((result) => setUserInfo(result[0]))
      .catch((error) => console.log("error", error));

    await fetch(
      "https://api.cca.distronix.in:3443/citizen/getReports?docType=Prescription",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setPrescriptionReports(result?.data))
      .catch((error) => console.log("error", error));
    await fetch(
      "https://api.cca.distronix.in:3443/citizen/getReports?docType=Diagnostic",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setDiagnosticReports(result?.data))
      .catch((error) => console.log("error", error));

    try {
      await fetch(
        "https://api.cca.distronix.in:3443/citizen/getHealthInfo",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => setAddHealthInfo(result?.data))
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handlePdf = async () => {
    try {
      const blobPdf = await pdf(
        <MyDocument
          userInfo={userInfo}
          prescriptionReports={prescriptionReports}
          diagnosticReports={diagnosticReports}
          getAddHealthInfo={getAddHealthInfo}
        />
      ).toBlob();
      setBlobData(blobPdf);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handlePdf();
  }, [userInfo, prescriptionReports, diagnosticReports, getAddHealthInfo]); // Update PDF when dataChanged state changes

  return <div>{blobData && <PdfViewer pdfBlob={blobData} />}</div>;
};
export default IndexPdf;
