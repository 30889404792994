import { Page, StyleSheet, View, Text, Link } from "@react-pdf/renderer";
import React from "react";
import { ReportType, UserInfoType } from "../../../Typing";
import moment from "moment";

type Props = {
  userInfo: UserInfoType | undefined;
};

const PdfHomePage = ({ userInfo }: Props) => {
  const styles = StyleSheet.create({
    body: {
      flex: 1,
      // alignItems: "center",
      // justifyContent: "center",
      // width: "100%",
      height: "100%",
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      fontFamily: "Oswald",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
    },
    subtitle: {
      fontSize: 18,
      fontFamily: "Oswald",
    },
    text: {
      margin: "12px",
      fontSize: 14,
      textAlign: "justify",
      fontFamily: "Times-Roman",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 32,
      color: "#172554",
      textAlign: "center",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginRight: "17px",
      marginTop: "50px",
    },
    details: {
      display: "flex",
      flexDirection: "row",
      fontSize: "20px",
      alignItems: "center",
      width: "100%",
      margin: "7px",
    },
    detailHeader: {
      width: "30%",
      textAlign: "left",
      marginLeft: "10px",
      marginRight: "5px",
      fontSize: "18px",
    },
    detailValue: {
      height: "42px",
      width: "70%",
      border: "1px solid gray",
      borderRadius: "5px",
      paddingLeft: "10px",
      paddingTop: "10px",
      fontSize: "18px",
    },
    detailTextArea: {
      height: "82px",
      width: "70%",
      border: "1px solid gray",
      borderRadius: "5px",
      paddingLeft: "10px",
      paddingTop: "10px",
      fontSize: "18px",
    },
  });

  return (
    <View style={{ display: "flex", flexDirection: "row", height: "100%" }}>
      <View style={{ backgroundColor: "#859FC4", width: "110px" }}></View>
      <View
        style={{
          backgroundColor: "#91CADA",
          width: "50px",
          marginLeft: "8px",
        }}
      ></View>
      <View
        style={{
          backgroundColor: "#B9D7D8",
          width: "20px",
          marginLeft: "8px",
        }}
      ></View>
      <View style={styles.container}>
        <View>
          <Text style={styles.header}>NKDA - Smart</Text>
          <Text style={styles.header}>Health Report</Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#173D60",
            width: "100%",
            margin: "30px",
          }}
        >
          <View style={styles.details}>
            <Text style={styles.detailHeader}>Patient Name</Text>
            <Text style={styles.detailValue}>{userInfo?.name}</Text>
          </View>
          <View style={styles.details}>
            <Text style={styles.detailHeader}>Email</Text>
            <Text style={styles.detailValue}>{userInfo?.email}</Text>
          </View>
          <View style={styles.details}>
            <Text style={styles.detailHeader}>Gender</Text>
            <Text style={styles.detailValue}>
              {userInfo?.sex === "M" ? "Male" : "Female"}
            </Text>
          </View>
          <View style={styles.details}>
            <Text style={styles.detailHeader}>Date of Birth</Text>
            <Text style={styles.detailValue}>
              {moment(userInfo?.date_of_birth).format("DD-MM-YYYY")}
            </Text>
          </View>
          <View style={styles.details}>
            <Text style={styles.detailHeader}>Medical Center</Text>
            <Text style={styles.detailValue}>{userInfo?.hospital_name}</Text>
          </View>
          <View style={styles.details}>
            <Text style={styles.detailHeader}>Age</Text>
            <Text style={styles.detailValue}>
              {Number(moment().format("YYYY")) -
                Number(moment(userInfo?.date_of_birth).format("YYYY"))}
            </Text>
          </View>
          <View style={styles.details}>
            <Text style={styles.detailHeader}>Phone No.</Text>
            <Text style={styles.detailValue}>{userInfo?.mob_no}</Text>
          </View>
          <View style={styles.details}>
            <Text style={styles.detailHeader}>Blood Group</Text>
            <Text style={styles.detailValue}>{userInfo?.blood_group}</Text>
          </View>
          <View style={styles.details}>
            <Text style={styles.detailHeader}>SHC No.</Text>
            <Text style={styles.detailValue}>{userInfo?.citizen_id}</Text>
          </View>
          <View style={styles.details}>
            <Text style={styles.detailHeader}>Address</Text>
            <Text style={styles.detailTextArea}>{userInfo?.address}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PdfHomePage;
