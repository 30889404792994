import { Page, StyleSheet, View, Text, Link, Image } from "@react-pdf/renderer";
import moment from "moment";

const PdfReportDia = ({ path, id, date, docType }: any) => {
  const ReportsStyle = StyleSheet.create({
    body: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    mainViewStyle: {
      border: 6,
      borderColor:
        docType.toUpperCase() === "DIAGNOSTIC" ? "#718eb2" : "#743523",
      width: "85%",
      height: "82%",
      overflow: "hidden",
      alignItems: "center",
      zIndex: 10,
    },
    headingStyle: {
      marginTop: -20,
      paddingVertical: 15,
      fontSize: 30,
      textAlign: "center",
      color: docType.toUpperCase() === "DIAGNOSTIC" ? "#718eb2" : "#743523",
      fontFamily: "Helvetica",
    },

    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    serialNoTextSTyle: {
      position: "absolute",
      transform: "rotate(-90deg)",
      left: -20,
    },
    imageStyle: {
      width: "100%",
      height: "72%",
      marginTop: 55,
    },
    dateTextSTyle: {
      position: "absolute",
      transform: "rotate(90deg)",
      zIndex: 100,
      right: -130,
    },
  });

  return (
    <View style={ReportsStyle.body}>
      {/* title */}
      <Text style={ReportsStyle.headingStyle}>
        {docType.toUpperCase()} REPORT
      </Text>
      {/* serial no. */}
      <Text style={ReportsStyle.serialNoTextSTyle}>Serial No.: {id + 1}</Text>
      {/* Date of upload text */}
      <Text style={ReportsStyle.dateTextSTyle}>
        Date of Upload : {moment(date).format("lll")}
      </Text>
      <View style={ReportsStyle.mainViewStyle}>
        <Image
          style={ReportsStyle.imageStyle}
          src={`https://api.cca.distronix.in:3443/${path}`}
        />
      </View>
    </View>
  );
};

export default PdfReportDia;
