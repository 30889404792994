import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Button, Modal } from "semantic-ui-react";
import Loader from "../Loader";

type Props = {
  onSelect?: ((data: any) => void) | undefined;
}

const OtpModal = (props: Props) => {
  const [otp, setOtp] = useState("");
  const [waitTime, setWaitTime] = useState(30);
  const [open, setOpen] = useState(true);
  const [click, setClick] = useState(false);
  const [verified, setVerified] = useState(false);

  const otpSubmit = () => {
    setClick(true);
    setInterval(() => {
      setVerified(true);
    }, 500)
    setInterval(() => {
      props.onSelect?.(true);
      setClick(false);
    }, 1300)
  }

  useEffect(() => {
    let myInterval: any = setInterval(
      //@ts-ignore
      () => {
        if (waitTime === 0) {
          clearInterval(myInterval);
        }
        if (waitTime > 0) {
          setWaitTime((prev) => prev - 1);
        }
      },
      1000
    );
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    setWaitTime(30);
  }, []);

  
  return (
    <Modal onClose={() => setOpen(true)} open={open} size="mini">
      <Modal.Content>
        {click &&
          <div className="absolute inline-flex justify-center items-center w-full h-full right-1">
            {!verified ? <Loader /> :
              <div className="flex flex-col items-center space-y-5">
                <i className="fa-solid fa-circle-check fa-beat-fade text-green-600 text-8xl"></i>
                <div className="text-green-600 text-3xl whitespace-nowrap">Verification Successfull</div>
              </div>
            }
          </div>}
        <div className={`form ${click && 'bg-gray-300 invisible'}`}>
          <h2 className="heading">Verify</h2>
          <svg className="check" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="60px" height="60px" viewBox="0 0 60 60" xmlSpace="preserve">  <image id="image0" width="60" height="60" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAQAAACQ9RH5AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
              AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
              cwAACxMAAAsTAQCanBgAAAAHdElNRQfnAg0NDzN/r+StAAACR0lEQVRYw+3Yy2sTURTH8W+bNgVf
              aGhFaxNiAoJou3FVEUQE1yL031BEROjCnf4PLlxILZSGYncuiiC48AEKxghaNGiliAojiBWZNnNd
              xDza3pl77jyCyPzO8ubcT85wmUkG0qT539In+MwgoxQoUqDAKDn2kSNLlp3AGi4uDt9xWOUTK3xg
              hVU2wsIZSkxwnHHGKZOxHKfBe6rUqFGlTkPaVmKGn6iYao1ZyhK2zJfY0FZ9ldBzsbMKxZwZjn/e
              5szGw6UsD5I0W6T+hBhjUjiF7bNInjz37Ruj3igGABjbtpIo3GIh30u4ww5wr3fwfJvNcFeznhBs
              YgXw70TYX2bY/ulkZhWfzfBbTdtrzjPFsvFI+T/L35jhp5q2owDs51VIVvHYDM9sa/LY8XdtKy1l
              FXfM8FVN2/X2ajctZxVXzPA5TZvHpfb6CFXxkerUWTOcY11LX9w0tc20inX2mmF4qG3upnNWrOKB
              hIXLPu3dF1x+kRWq6ysHpkjDl+7eQjatYoOCDIZF3006U0unVSxIWTgTsI3HNP3soSJkFaflMDwL
              3OoHrph9YsPCJJ5466DyOGUHY3Epg2rWloUxnMjsNw7aw3AhMjwVhgW4HYm9FZaFQZ/bp6QeMRQe
              hhHehWKXGY7CAuSpW7MfKUZlAUqWdJ3DcbAAB3guZl9yKC4WYLfmT4muFtgVJwvQx7T2t0mnXK6J
              XlGGyAQvfNkaJ5JBmxnipubJ5HKDbJJsM0eY38QucSx5tJWTVHBwqDDZOzRNmn87fwDoyM4J2hRz
              NgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wMi0xM1QxMzoxNTo1MCswMDowMKC8JaoAAAAldEVY
              dGRhdGU6bW9kaWZ5ADIwMjMtMDItMTNUMTM6MTU6NTArMDA6MDDR4Z0WAAAAKHRFWHRkYXRlOnRp
              bWVzdGFtcAAyMDIzLTAyLTEzVDEzOjE1OjUxKzAwOjAwIIO3fQAAAABJRU5ErkJggg=="></image>
          </svg>
          <OtpInput
            containerStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 20,
            }}
            inputStyle={{ width: "30px", height: "30px" }}
            value={otp}
            onChange={(e: any) => setOtp(e)}
            className="border-2 rounded outline-none mt-7"
          />
          {waitTime > 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: '10px 42px',
              }}
            >
              <p style={{ marginTop: 15 }}>Did't receive an otp ?</p>
              <p style={{ color: "red" }}>
                Wait for {waitTime < 10 ? `0${waitTime}` : waitTime} sec
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 0px",
              }}
            >
              <Button.Group>
                <Button color='red'>Resend</Button>
                <Button.Or />
                <Button positive>Send To Kin</Button>
              </Button.Group>
            </div>
          )}
          <div className={`w-full flex justify-center mb-3 ${click && 'invisible'}`}>
            <button className="btn2" onClick={otpSubmit}>
              <span className="button-content">Submit</span>
            </button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default OtpModal;
