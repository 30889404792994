import "./App.css";

import Loader from "./components/Loader";
import { ModalOpen } from "./components/PdfViewer/ModalOpen";
import { useEffect, useState } from "react";
import { otp } from "./assets";
import { GetReports } from "./api/getReports";

function App() {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const [data, setData] = useState<any>({
    token: "",
    citizen: "",
  });

  const params = new URLSearchParams(window.location.href.split("?")[1]);

  const SaveTransaction = async () => {
    const res = await GetReports({
      key: params.get("accessToken"),
      lat: params.get("lat"),
      long: params.get("long"),
    });
    console.log(res);
  };

  useEffect(() => {
    console.log(
      params.get("accessToken"),
      params.get("lat"),
      params.get("long")
    );
    setData({
      ...params,
      token: params.get("accessToken"),
      citizen: params.get("citizen"),
    });
    params.get("accessToken") && SaveTransaction();
  }, []);

  return (
    <div className="w-screen h-screen">
      <img src={otp} alt="" className="w-full h-full z-0" />
      <ModalOpen open={open} onClose={handleClose} cancel={true} />
    </div>
  );
}

export default App;
