import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import OtpModal from "../OtpModal/OtpModal";
import Loader from "../Loader";
import IndexPdf from "../Pdfs/IndexPdf";

type Props = {
  open: boolean;
  onClose: () => void;
  cancel?: any;
};

export const ModalOpen = (props: Props) => {
  const [isAccessToken, setIsAccessToken] = useState<any>(null);

  const params = new URLSearchParams(window.location.href.split("?")[1]);
  useEffect(() => {
    setIsAccessToken(
      params.get("accessToken") || params.get("citizen") || null
    );
  }, []);



  const defaultLayoutPluginInstanceMobile = defaultLayoutPlugin();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const defaultLayout =
    window.screen.availWidth <= 450
      ? defaultLayoutPluginInstanceMobile
      : defaultLayoutPluginInstance;
  const _width = window.screen.availWidth <= 450 ? 0.27 : 0.7;

  const [otp, setOtp] = useState(false);

  const selectHandler = (values: any) => {
    setOtp(true);
  };

  return (
    <>
      {otp ? (
        <Dialog
          fullScreen
          scroll="paper"
          open={props.open}
          onClose={props.onClose}
          sx={{}}
        >
          <DialogContent dividers sx={{ padding: "0px" }}>
            <div
              style={{
                height: "100%",
              }}
            >
              {isAccessToken ? (
                <IndexPdf />
              ) : (
                <div className="flex justify-center items-center h-screen">
                  <Loader />
                </div>
              )}
            </div>
          </DialogContent>
          {!props.cancel && (
            <DialogActions>
              <Button onClick={props.onClose} color="primary">
                Dismiss
              </Button>
            </DialogActions>
          )}
        </Dialog>
      ) : (
        // <PDFViewer className="w-full h-screen" showToolbar={true}>
        //   <MyDocument />
        // </PDFViewer>
        <OtpModal onSelect={selectHandler} />
      )}
    </>
  );
};
