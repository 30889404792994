import axios, { AxiosRequestConfig } from "axios";

export const GetReports = async ({
  key,
  lat,
  long,
}: {
  key: string | null;
  lat: string | any;
  long: string | any;
}) => {
  var config: AxiosRequestConfig = {
    method: "get",
    url: `https://api.cca.distronix.in:3443/citizen/getReports?docType=all&lat=${lat}&long=${long}`,
    headers: {
      authorization: key || "",
    },
  };
  const result = await axios(config)
    .then((res) => res.data)
    .catch((e) => ({ error: e, success: false }));
  console.log(result);
  return result;
};
