import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Import default styles
import "@react-pdf-viewer/default-layout/lib/styles/index.css"; // Import default layout styles
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
interface PdfViewerProps {
  pdfBlob: Blob;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfBlob }) => {
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => {
      setPdfUrl(reader.result as string);
    };
    reader.readAsDataURL(pdfBlob);
  }, [pdfBlob]);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {pdfUrl && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
          <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
      )}
    </div>
  );
};

export default PdfViewer;
