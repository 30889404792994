import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { AddHealthInfoType } from "../../../Typing";
interface Props {
  getAddHealthInfo: AddHealthInfoType[];
}

const AdditionalPatientInformation = ({ getAddHealthInfo }: Props) => {
  const Style = StyleSheet.create({
    textStyle: {
      textAlign: "left",
      paddingVertical: 10,
      fontSize: 18,
      paddingHorizontal: 10,
    },
    textItems: {
      textAlign: "left",
      paddingHorizontal: 10,
      fontSize: 10,
      marginTop: 5,
      marginBottom: 5,
    },
  });

  var medications = [];
  const medicationObject: any = getAddHealthInfo[0].medication;
  for (const key in medicationObject) {
    if (medicationObject.hasOwnProperty(key)) {
      const item = key + " : " + medicationObject[key];
      medications.push(item);
    }
  }
  return (
    <View style={{ display: "flex", flexDirection: "row", height: "100%" }}>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View
          style={{ backgroundColor: "#859FC4", width: "98px", height: "100%" }}
        />
        <View
          style={{
            backgroundColor: "#91CADA",
            width: "44px",
            marginLeft: "8px",
            height: "100%",
          }}
        />
        <View
          style={{
            backgroundColor: "#B9D7D8",
            width: "18px",
            marginLeft: "8px",
            height: "100%",
          }}
        />
      </View>
      {/* Content sections */}
      <View
        style={{
          width: "100%",
        }}
      >
        <Text
          style={{
            textAlign: "center",
            fontFamily: "Helvetica",
            paddingTop: 80,
            paddingBottom: 40,
            fontSize: 28,
          }}
        >
          Aditional Patient Information
        </Text>
        <Text style={Style.textStyle}>Health Concern:</Text>
        <Text style={Style.textItems}>
          {getAddHealthInfo[0]?.health_concern}
        </Text>
        <Text style={Style.textStyle}>Previous Diagnosis Conditions:</Text>
        <Text style={[Style.textItems]}>
          {getAddHealthInfo[0]?.prev_diag_cond.map((item) => `${item} ,`)}
        </Text>
        <Text style={Style.textStyle}>Medications: {}</Text>
        {medications.map((item) => (
          <Text style={[Style.textItems]}>{item}</Text>
        ))}

        <Text style={Style.textStyle}>Allergies: {}</Text>
        <Text style={[Style.textItems]}>
          {getAddHealthInfo[0]?.allergies?.map((item) => `${item} ,`)}
        </Text>
        <Text style={Style.textStyle}>Major Surgery: {}</Text>
        <Text style={[Style.textItems]}>
          {getAddHealthInfo[0]?.major_surgery?.map((item: any) => `${item} ,`)}
        </Text>
        <Text style={Style.textStyle}>Major Illness: {}</Text>
        <Text style={[Style.textItems]}>
          {getAddHealthInfo[0]?.major_illness?.map((item) => `${item} ,`)}
        </Text>
      </View>
    </View>
  );
};

export default AdditionalPatientInformation;
